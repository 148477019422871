import React,{ useState,useEffect }  from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Row,Col,Button} from "react-bootstrap";
import "react-activity/dist/Spinner.css";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Spinner } from "react-activity";
import Http from '../../libs/http'
import { HeaderBotones } from '../../header/header_botones'


class ViajeEspana extends React.Component {

    state = {
        loadInfo: false,
    };


    constructor(props) {
        super(props);
    }


    async componentDidMount() {
        this.setState({isMobileSend:this.props.isMobileSend})        
    }

    render() {
      return (
        <Row className={this.state.isMobileSend ? "movil noPadding":""}>
            <Col xs={12} className={this.state.isMobileSend ? "noPadding":"" }>
                {
                    this.state.isMobileSend ?
                    null:
                    <HeaderBotones status={this.props.status} activeTrack={this.props.activeTrack} />
                }
                <div className="clear"></div>

                <div className="clear"></div>

                {
                    this.state.loadingTiendas == false ?
                        <div className="contSpinner"><Spinner /></div>
                    :
                    this.state.isMobileSend == false ?
                    <div style={{padding:"16px"}} className={this.state.isMobileSend ? "altoMinimoMovil contMainMovil":"contAcercaDe"}>
                    <a href='/terminosderifa' title="Llaollao te lleva a España">
                        <img className="logo-image" src="images/rifa_llaollao_desktop.jpg" alt="llaollao" style={this.state.isMobileSend ? {width:"100%",borderRadius:"8px",padding:"0px",margin:"0px",marginBottom:"20px"} : {width:"100%",marginLeft:"0%"}} />
                    </a>
                </div>
                    :
                    <div style={{padding:"16px"}} className={this.state.isMobileSend ? "contMainMovil":"contAcercaDe"}>
                        <a href='/terminosderifa'  title="Llaollao te lleva a España">
                            <img className="logo-image" src="images/rifa_llaollao_movile.jpg" alt="llaollao" style={this.state.isMobileSend ? {width:"100%",borderRadius:"8px",padding:"0px",margin:"0px",marginBottom:"20px"} : {width:"70%",marginLeft:"15%"}} />
                        </a>
                    </div>
                }

                

                
            </Col>

        </Row>
      );
    }
}

export default ViajeEspana;