import React,{ useState,useEffect }  from 'react';
import { Row,Col,Button} from "react-bootstrap";
import Http from '../../libs/http'
import { Route, Redirect } from 'react-router'
import Modal from 'react-modal';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import InputMask from 'react-input-mask';
import validator from 'validator'
import { withRouter } from 'react-router-dom';
import $ from 'jquery'; 
import { NavLink } from "react-router-dom";
import { Spinner } from "react-activity";
import "react-activity/dist/Spinner.css";
import Select from 'react-select'

class CrearCuentaMovil extends React.Component {

    state = {
        loading: false,
        setIsOpen: false,
        selectedIndex: 0,
        errorNombre:false,
        nombre:"",
        telefono:"",
        dui:"",
        pass:"",
        apellido:"",
        email:"",
        dia:"",
        mes:"",
        year:"",
        departamento:"",
        departamentos:[],
        municipios:[],
        municipio:"",
        colonia:"",
        colonias:[],
        tipoDireccion:"",
        direccion:"",
        nombreDireccion:"",
        terminos:false,
        errorMensaje:"",
        userDataLoading: true,
        coloniaNombre:null,
        options:  [],
        tipoDocumento:"",
        genero:"",
    };

    handleClickTab(e,valor) {
        this.setState({selectedIndex:valor})
        //alert(this.state.defaultIndexTabs)
    }

    handleSelectTabs = index => {
        this.setState({ selectedIndex: index });
    };

    validarPrimerFormulario = async (e) => {
        var validado = true
        if(this.state.nombre == ""){
            this.setState({errorNombre:true})
            validado = false
        }

        if(this.state.telefono == ""){
            this.setState({errorTelefono:true})
            validado = false
        }else if(this.state.telefono.length != 8){
            this.setState({errorTelefono:true})
            validado = false
        }

        if(this.state.tipoDocumento == ""){
            this.setState({errorTipoDocumento:true})
            validado = false
        }


        if(this.state.dui == ""){
            this.setState({errorDui:true})
            validado = false
        }


        if(this.state.genero == ""){
            this.setState({errorGenero:true})
            validado = false
        }

        if(this.state.pass.length < 8){
            this.setState({errorPass:true,errorPassText:"INGRESE AL MENOS 8 CARACTERES."})
            validado = false
        }else if(this.state.pass != this.state.passRepetir){
            this.setState({errorPassRepetir:true})
            validado = false
        }
        
        if(this.state.apellido == ""){
            this.setState({errorApellido:true})
            validado = false
        }

        if(this.state.email == ""){
            this.setState({errorEmail:true,errorEmailText:"campo obligatorio"})
            validado = false
        }else if(validator.isEmail(this.state.email) == false){
            this.setState({errorEmail:true,errorEmailText:"Email no valido"})
            validado = false
        }

        const date = this.state.year+'/'+this.state.mes+'/'+this.state.dia;

        if (validator.isDate(date)) {            
            this.setState({errorFecha:false,errorFechaTexto:""})
        } else {
            this.setState({errorFecha:true,errorFechaTexto:"Fecha no valida"})
            validado = false
        }

        if(validado == false){
            window.scrollTo(0, 0);
        }

        if(this.state.nombreDireccion == ""){
            this.setState({errorNombreDireccion:true})
            validado = false
        }

        if(this.state.departamento == ""){
            this.setState({errorDepartamento:true})
            validado = false
        }

        if(this.state.municipio == ""){
            this.setState({errorMunicipio:true})
            validado = false
        }

        if(this.state.colonia == ""){
            this.setState({errorColonia:true})
            validado = false
        }

        if(this.state.tipoDireccion == ""){
            this.setState({errorTipoDireccion:true})
            validado = false
        }

        if(this.state.direccion == ""){
            this.setState({errorDireccion:true})
            validado = false
        }

        if(this.state.terminos == false){
            this.setState({errorTerminos:true})
            validado = false
        }

        if(validado == true){
            //console.log(en)
            var dataUserSend = {}
            dataUserSend.nombre = this.state.nombre
            dataUserSend.apellido = this.state.apellido
            dataUserSend.telefono = this.state.telefono
            dataUserSend.email = this.state.email
            dataUserSend.dui = this.state.dui
            dataUserSend.gender = this.state.genero
            dataUserSend.typeIdentity = this.state.tipoDocumento
            dataUserSend.cumple = `${this.state.dia}/${this.state.mes}/${this.state.year}`
            dataUserSend.contra = this.state.pass
            
            var dataUserDireccion = {}
            dataUserDireccion.alias = this.state.nombreDireccion
            dataUserDireccion.departamento = parseInt(this.state.departamento)
            dataUserDireccion.municipio = parseInt(this.state.municipio)
            dataUserDireccion.zona = parseInt(this.state.colonia)
            dataUserDireccion.tipo = parseInt(this.state.tipoDireccion)
            dataUserDireccion.direccion = this.state.direccion

            dataUserSend.direccion = dataUserDireccion
            console.log(dataUserSend)

            this.setState({loadingDataSend:true,errorMensaje:""})

            const res = await Http.instance.post("rest/account/register",JSON.stringify(dataUserSend));

            if(typeof res.errorHttp === 'undefined'){
                if(res.status == 200){
                    this.setState({loadingDataSend:false,errorMensaje:""})
                    localStorage.removeItem("direccionSelect")
                    localStorage.setItem("customer", JSON.stringify(res.customer));
                    window.location = "/";
                }else{
                    var  mensaje = res.message
                    this.setState({loadingDataSend:false,errorMensaje:mensaje})
                }
            }else{
                this.setState({loadingDataSend:false,errorMensaje:"Ha ocurrido un error"})
            }

            //localStorage.setItem("name", JSON.stringify(name));


        }
    }


    constructor(props) {
        super(props);
        //this.handleClickTab = this.handleClickTab.bind(this);

    } 

    async componentDidMount() {
        const res =  await Http.instance.get("rest/main/departments");
        this.setState({departamentos:res})
    }

    render() {
      return (
          <div  className="noPadding">
            <Row>
                <div className="contNuestrasTiendas noPadding">

                    <NavLink className="navlink" to={"/"} activeClassName="off" title="Home">
                        <img src="/images/retornar.png" className="retornar" />
                    </NavLink>
                   

                    <div className="nuestrasTiendas">Crear una cuenta</div>
                    <Row className="contListTiendas noPadding">
                        <Col xs={12} className="contRegistroLeft h-100">
                            <Row>
                                <Col xs={1}></Col>
                                <Col xs={10}>
                                    <div className="subTituloCrear">Al crear tu cuenta podrás comprar dentro de la tienda y recibir tus pedidos a domicilio</div>
                                    <form>
                                        <div className="tituloInformacionPersonal">Información personal</div>
                                        <Row className="rowRegistro">

                                            
                                            <div style={{marginTop:"30px"}}>
                                                <div className="tituloCampo">
                                                    <i className="glyphicon glyphicon-user"></i> &nbsp;&nbsp;NOMBRES
                                                </div>
                                                <input 
                                                onChange={(text) => {
                                                    this.setState({nombre:text.target.value,errorNombre:false})
                                                }}
                                                value={this.state.nombre}
                                                className={this.state.errorNombre ? "error" : ""} 
                                                id="firstName"
                                                maxchar="1"
                                                name="firstName" type="text" maxlength="30" />
                                                {
                                                    this.state.errorNombre == true ?
                                                        <label className="error">campo obligatorio.</label>
                                                    :null
                                                }
                                                

                                            </div>

                                            
                                            <div>
                                                <div className="tituloCampo">
                                                    <i className="glyphicon glyphicon-user"></i> &nbsp;&nbsp;APELLIDOS
                                                </div>
                                                <input 
                                                onChange={(text) => {
                                                    this.setState({apellido:text.target.value,errorApellido:false})
                                                }}
                                                maxchar="2"
                                                value={this.state.apellido}
                                                className={this.state.errorApellido ? "error" : ""} 
                                                required="true" type="text" maxlength="30" aria-required="true" aria-invalid="false" />
                                                {
                                                    this.state.errorApellido == true ?
                                                        <label className="error">campo obligatorio.</label>
                                                    :null
                                                }
                                            </div>

                                           
                                                

                                            <div>
                                                <div className="tituloCampo">
                                                    <i className="glyphicon glyphicon-phone"></i> &nbsp;&nbsp;TELÉFONO
                                                </div>
                                                <InputMask 
                                                onChange={(text) => {
                                                    const valor = text.target.value.replace(/\D/g, '')
                                                    this.setState({telefono:valor,errorTelefono:false})
                                                }}
                                                value={this.state.telefono}
                                                className={this.state.errorTelefono ? "error" : ""} 
                                                id="phoneNumber" mask="9999-9999" name="phoneNumber" placeholder="0000-0000"  inputmode="tel" required="true" type="tel" aria-required="true" aria-invalid="false" />
                                                {
                                                    this.state.errorTelefono == true ?
                                                        <label className="error">campo obligatorio.</label>
                                                    :null
                                                }
                                            </div>
                                            
                                            <div>
                                                <div className="tituloCampo">
                                                <i className="bi bi-envelope"></i>

                                                    <i className="glyphicon glyphicon-envelope"></i> &nbsp;&nbsp;EMAIL
                                                </div>
                                                <input 
                                                onChange={(text) => {
                                                    this.setState({email:text.target.value,errorEmail:false,errorEmailText:""})
                                                }}
                                                value={this.state.email}
                                                className={this.state.errorEmail ? "error" : ""} 
                                                type="email" id="emailAddress" name="emailAddress" required="" aria-required="true"  aria-invalid="true" />
                                                {
                                                    this.state.errorEmail == true ?
                                                        <label className="error">{this.state.errorEmailText}.</label>
                                                    :null
                                                }
                                            </div>


                                            <div>
                                                <div>
    
                                                
                                                <div className="tituloCampo">
                                                    <i className="glyphicon glyphicon-file"></i> &nbsp;&nbsp;TIPO DOCUMENTO
                                                </div>
                                                <div>
                                                    <select 
                                                    onChange={(text) => {
                                                        this.setState({tipoDocumento:text.target.value,errorTipoDocumento:false,errorTipoDocumentoTexto:""})
                                                    }}
                                                    style={{width:"100%"}}
                                                    value={this.state.tipoDocumento}
                                                    className={this.state.errorTipoDocumento ? "error inputSelectBirthDay birthDay" : "inputSelectBirthDay birthDay"} 
                                                    id="birthDay" name="birthDay">
                                                        <option value="">Seleccionar</option>
                                                        <option value="7">Documento Único Identidad</option>
                                                        <option value="8">Pasaporte</option>
                                                        <option value="12">Carné de Residencia</option>
                                                    </select>
                                                </div>
    
                                                {
                                                    this.state.errorTipoDocumento == true ?
                                                        <label className="error">campo obligatorio.</label>
                                                    :null
                                                }
    
                                                </div>
                                            </div>

                                            

                                            <div>
                                                <div>
                                                    <div className="tituloCampo">
                                                        <i className="glyphicon glyphicon-file"></i> &nbsp;&nbsp;DOCUMENTO
                                                    </div>
                                                    <input 
                                                    onChange={(text) => {
                                                        const valor = text.target.value.replaceAll("_", "");
                                                        console.log(valor)
                                                        this.setState({dui:valor,errorDui:false})
                                                    }}
                                                    value={this.state.dui}
                                                    className={this.state.errorDui ? "error" : ""} 
                                                    id="duiNumber" name="phoneNumber"  type="text" />
                                                    {
                                                        this.state.errorDui == true ?
                                                            <label className="error">campo obligatorio.</label>
                                                        :null
                                                    }
                                                </div>
                                            </div>
                                          
                                            <div>
                                                <div>
    
                                                
                                                <div className="tituloCampo">
                                                    <i className="glyphicon glyphicon-file"></i> &nbsp;&nbsp;GENERO
                                                </div>
                                                <div>
                                                    <select 
                                                    onChange={(text) => {
                                                        this.setState({genero:text.target.value,errorGenero:false,errorGeneroTexto:""})
                                                    }}
                                                    style={{width:"100%"}}
                                                    value={this.state.genero}
                                                    className={this.state.errorGenero ? "error inputSelectBirthDay birthDay" : "inputSelectBirthDay birthDay"} 
                                                    id="genero" name="genero">
                                                        <option value="">Seleccionar</option>
                                                        <option value="male">Hombre</option>
                                                        <option value="female">Mujer</option>
                                                    </select>
                                                </div>
    
                                                {
                                                    this.state.errorGenero == true ?
                                                        <label className="error">campo obligatorio.</label>
                                                    :null
                                                }
    
                                                </div>
                                            </div>
                                            

                                           
                                            <div>

                                                
                                                <div className="tituloCampo">
                                                    <i className="glyphicon glyphicon-gift"></i> &nbsp;&nbsp;CUMPLEAÑOS
                                                </div>
                                                <div>
                                                    <select 
                                                    onChange={(text) => {
                                                        this.setState({dia:text.target.value,errorFecha:false,errorFechaTexto:""})
                                                    }}
                                                    value={this.state.dia}
                                                    className={this.state.errorFecha ? "error inputSelectBirthDay birthDay" : "inputSelectBirthDay birthDay"} 
                                                    id="birthDay" name="birthDay">
                                                        <option value="">Día</option>
                                                        <option value="01">1</option>
                                                        <option value="02">2</option>
                                                        <option value="03">3</option>
                                                        <option value="04">4</option>
                                                        <option value="05">5</option>
                                                        <option value="06">6</option>
                                                        <option value="07">7</option>
                                                        <option value="08">8</option>
                                                        <option value="09">9</option>
                                                        <option value="10">10</option>
                                                        <option value="11">11</option>
                                                        <option value="12">12</option>
                                                        <option value="13">13</option>
                                                        <option value="14">14</option>
                                                        <option value="15">15</option>
                                                        <option value="16">16</option>
                                                        <option value="17">17</option>
                                                        <option value="18">18</option>
                                                        <option value="19">19</option>
                                                        <option value="20">20</option>
                                                        <option value="21">21</option>
                                                        <option value="22">22</option>
                                                        <option value="23">23</option>
                                                        <option value="24">24</option>
                                                        <option value="25">25</option>
                                                        <option value="26">26</option>
                                                        <option value="27">27</option>
                                                        <option value="28">28</option>
                                                        <option value="29">29</option>
                                                        <option value="30">30</option>
                                                        <option value="31">31</option>
                                                </select>

                                                <select 
                                                    onChange={(text) => {
                                                        this.setState({mes:text.target.value,errorFecha:false,errorFechaTexto:""})
                                                    }}
                                                    value={this.state.mes}
                                                    className={this.state.errorFecha ? "error inputSelectBirthDay birthMonth" : "inputSelectBirthDay birthMonth"} 
                                                    id="birthMonth" name="birthMonth" aria-invalid="false">
                                                    <option value="">Mes</option>
                                                        <option value="01">Enero</option>
                                                        <option value="02">Febrero</option>
                                                        <option value="03">Marzo</option>
                                                        <option value="04">Abril</option>
                                                        <option value="05">Mayo</option>
                                                        <option value="06">Junio</option>
                                                        <option value="07">Julio</option>
                                                        <option value="08">Agosto</option>
                                                        <option value="09">Septiembre</option>
                                                        <option value="10">Octubre</option>
                                                        <option value="11">Noviembre</option>
                                                        <option value="12">Diciembre</option>
                                                </select>

                                                    <select 
                                                        onChange={(text) => {
                                                            this.setState({year:text.target.value,errorFecha:false,errorFechaTexto:""})
                                                        }}
                                                        value={this.state.year}
                                                        className={this.state.errorFecha ? "error inputSelectBirthDay birthYear" : "inputSelectBirthDay birthYear"} 
                                                        id="birthYear" name="birthYear" aria-invalid="false">
                                                        <option value="">Año</option>
                                                        <option value="2004">2004</option>
                                                        <option value="2003">2003</option><option value="2002">2002</option><option value="2001">2001</option><option value="2000">2000</option><option value="1999">1999</option><option value="1998">1998</option><option value="1997">1997</option><option value="1996">1996</option><option value="1995">1995</option><option value="1994">1994</option><option value="1993">1993</option><option value="1992">1992</option><option value="1991">1991</option><option value="1990">1990</option><option value="1989">1989</option><option value="1988">1988</option><option value="1987">1987</option><option value="1986">1986</option><option value="1985">1985</option><option value="1984">1984</option><option value="1983">1983</option><option value="1982">1982</option><option value="1981">1981</option><option value="1980">1980</option><option value="1979">1979</option><option value="1978">1978</option><option value="1977">1977</option><option value="1976">1976</option><option value="1975">1975</option><option value="1974">1974</option><option value="1973">1973</option><option value="1972">1972</option><option value="1971">1971</option><option value="1970">1970</option><option value="1969">1969</option><option value="1968">1968</option><option value="1967">1967</option><option value="1966">1966</option><option value="1965">1965</option><option value="1964">1964</option><option value="1963">1963</option><option value="1962">1962</option><option value="1961">1961</option><option value="1960">1960</option><option value="1959">1959</option><option value="1958">1958</option><option value="1957">1957</option><option value="1956">1956</option><option value="1955">1955</option><option value="1954">1954</option><option value="1953">1953</option><option value="1952">1952</option><option value="1951">1951</option><option value="1950">1950</option>
                                                    </select>
                                                </div>

                                                {
                                                    this.state.errorFecha == true ?
                                                        <label className="error">{this.state.errorFechaTexto}.</label>
                                                    :null
                                                }

                                            </div>
                                            

                                             

                                            <div>
                                                <div className="tituloCampo">
                                                    <i className="glyphicon glyphicon-lock"></i> &nbsp;&nbsp;CONTRASEÑA
                                                </div>
                                                <input 
                                                onChange={(text) => {
                                                    this.setState({pass:text.target.value,errorPass:false})
                                                }}
                                                value={this.state.pass}
                                                className={this.state.errorPass ? "error" : ""} 
                                                type="password" autocomplete="off" id="createPassword" name="createPassword" required="" aria-required="true" aria-invalid="true"></input>
                                                {
                                                    this.state.errorPass == true ?
                                                        <label className="error">{this.state.errorPassText}</label>
                                                    :null
                                                }
                                            </div>



                                            
                                                
                                            <div>
                                                <div className="tituloCampo">
                                                    <i className="glyphicon glyphicon-lock"></i> &nbsp;&nbsp;REPETIR CONTRASEÑA
                                                </div>
                                                <input 
                                                onChange={(text) => {
                                                    this.setState({passRepetir:text.target.value,errorPassRepetir:false})
                                                }}
                                                value={this.state.passRepetir}
                                                className={this.state.errorPassRepetir ? "error" : ""} 
                                                type="password" autocomplete="off" id="createPassword" name="createPassword" required="" aria-required="true" aria-invalid="true"></input>
                                                {
                                                    this.state.errorPassRepetir == true ?
                                                        <label className="error">Password no coincide.</label>
                                                    :null
                                                }
                                            
                                            </div>
                                        </Row>


                                        <div className="tituloInformacionPersonal">Dirección principal</div>
                                        <div className="subTituloCrear">Ingresa tu dirección principal. Luego de crear tu cuenta podrás ingresar múltiples direcciones </div>

                                        <Row className="rowRegistro">
                                                
                                                <div style={{marginTop:"30px"}}>
                                                    <div className="tituloCampo">
                                                        <i className="glyphicon glyphicon-exclamation-sign"></i>&nbsp;&nbsp;TIPO DE DIRECCIÓN
                                                    </div>
                                                    <select 
                                                        onChange={(text) => {
                                                            this.setState({tipoDireccion:text.target.value,errorTipoDireccion:false})
                                                        }}
                                                        value={this.state.tipoDireccion}
                                                        className={this.state.errorTipoDireccion ? "error" : ""} 
                                                        id="colonia" name="colonia">
                                                            <option value="">Seleccionar</option>
                                                            <option value="1">Casa</option>
                                                            <option value="2">Oficina</option>
                                                            <option value="3">Apartamento</option>
                                                            <option value="4">Otros</option>
                                                    </select>
                                                </div>
                                                {
                                                    this.state.errorTipoDireccion == true ?
                                                        <label className="error">campo obligatorio.</label>
                                                    :null
                                                }


                                                <div>
                                                    <div className="tituloCampo">
                                                        <i className="glyphicon glyphicon-exclamation-sign"></i>&nbsp;&nbsp;NOMBRE DE UBICACIÓN
                                                    </div>
                                                    <input 
                                                    onChange={(text) => {
                                                        this.setState({nombreDireccion:text.target.value,errorNombreDireccion:false})
                                                    }}
                                                    value={this.state.nombreDireccion}
                                                    className={this.state.errorNombreDireccion ? "error" : ""} 
                                                    id="firstName"
                                                    maxchar="1"
                                                    name="firstName" type="text" maxlength="30" />
                                                    {
                                                        this.state.errorNombreDireccion == true ?
                                                            <label className="error">campo obligatorio.</label>
                                                        :null
                                                    }
                                                    
    
                                                </div>

                                                

                                                <div>
                                                    <div className="tituloCampo">
                                                        <i className="glyphicon glyphicon-exclamation-sign"></i>&nbsp;&nbsp;DEPARTAMENTO
                                                    </div>
                                                    <select 
                                                    onChange={(text) => {
                                                        console.log("aqui",text.target.value)
                                                        var listMunicipios = this.state.departamentos.filter((item) => item.id == text.target.value)
            
                                                        this.setState({departamento:text.target.value,errorDepartamento:false,municipios:listMunicipios[0].items,coloniaNombre:null,colonia:"",municipio:""})
            
                                                        $("#municipio").prop("selectedIndex", 0);

                                                    }}
                                                    value={this.state.departamento}
                                                    className={this.state.errorDepartamento ? "error" : ""} 
                                                    id="departamento" name="departamento">
                                                        <option value="">Seleccionar</option>

                                                        {this.state.departamentos.map(item => (
                                                            <option value={item.id}>{item.label}</option>
                                                        ))}

                                                    </select>
                                                    {
                                                        this.state.errorDepartamento == true ?
                                                            <label className="error">campo obligatorio.</label>
                                                        :null
                                                    }
                                                </div>

                                              
                                                <div>
                                                    <div className="tituloCampo">
                                                        <i className="glyphicon glyphicon-exclamation-sign"></i>&nbsp;&nbsp;MUNICIPIO
                                                    </div>
                                                    <select 
                                                        onChange={async (text) => {
                                                            this.setState({municipio:text.target.value,errorMunicipio:false})

                                                            const resColonias =  await Http.instance.get(`rest/main/zones/${text.target.value}`);
                                                           
                                                            var options = []
                                                            resColonias.map(item => (
                                                               options.push({value:item.id,label:item.name_online})
                                                            ));
                                                                                                                
                                                            this.setState({colonias:resColonias,options:options,coloniaNombre:null,colonia:""})


                                                        }}
                                                        value={this.state.municipio}
                                                        className={this.state.errorMunicipio ? "error" : ""} 
                                                        id="municipio" name="municipio">
                                                        <option value="">Seleccionar</option>
                                                        {this.state.municipios.map(item => (
                                                            <option value={item.id}>{item.name}</option>
                                                        ))}
                                                    </select>
                                                    {
                                                        this.state.errorMunicipio == true ?
                                                            <label className="error">campo obligatorio.</label>
                                                        :null
                                                    }
                                                </div>
                                           

                                                <div className='noInput'>
                                                    <div className="tituloCampo">
                                                        <i className="glyphicon glyphicon-exclamation-sign"></i>&nbsp;&nbsp;COLONIA
                                                    </div>

                                                    <Select  
                                                        onChange={(text) => {
                                                            console.log(text.label)
                                                            this.setState({colonia:text.value,coloniaNombre:text,errorColonia:false})
                                                        }}
                                                        placeholder="Seleccionar"
                                                        value={this.state.coloniaNombre}
                                                        className={this.state.errorColonia ? "errorSelect" : ""} 
                                                        options={this.state.options}
                                                        id="colonia" name="colonia">
                                                    
                                                    </Select >

                                                    {
                                                        this.state.errorColonia == true ?
                                                            <label className="error">campo obligatorio.</label>
                                                        :null
                                                    }
                                                </div>

                                            <div>
                                            <div className="tituloCampo">
                                                    <i className="glyphicon glyphicon-home"></i> &nbsp;&nbsp;DIRECCIÓN
                                                </div>
                                                <textarea 
                                                onChange={(text) => {
                                                    this.setState({direccion:text.target.value,errorDireccion:false})
                                                }}
                                                value={this.state.direccion}
                                                className={this.state.errorDireccion ? "error" : ""} 
                                                id="streetaddress" name="streetaddress" required="" ></textarea>
                                                {
                                                    this.state.errorDireccion == true ?
                                                        <label className="error">campo obligatorio.</label>
                                                    :null
                                                }                                
                                            </div>
                                                    
                                        </Row>
                                        
                                        <p className="inputCondiciones">
                                            <input 
                                            checked={this.state.terminos}
                                            onChange={(text) => {
                                                this.setState({terminos:text.target.checked,errorTerminos:!text.target.checked})
                                            }}
                                            id="create-account-terms" name="ageConsentTermsOfUse" type="checkbox" required="" aria-required="true" className="" />
                                            <label className="checkboxCustom" for="create-account-terms"></label>
                                            <label for="create-account-terms" className="acepto">Sí, acepto los 
                                                <a href="/terminoscondiciones" target="_blank" alt="Terminos de Uso" className="terminos"> Términos y Condiciones de Uso </a>.
                                            </label>
                                            <label  className={this.state.errorTerminos ? "error" : ""}  style={{display: this.state.errorTerminos ? "" : "none", width: "100%",textAlign: "center"}}>campo obligatorio.</label>
                                        </p>
                                        <div className='errorFormulario'>{this.state.errorMensaje}</div>
                                        <Button onClick={(e) => this.validarPrimerFormulario(e)} className="btn-agregar btnContinuarCrearCuenta">
                                            {
                                                this.state.loadingDataSend == true ?
                                                    <div className="contSpinner" style={{padding:"0px"}}><Spinner /></div>
                                                : 
                                                "CREAR CUENTA"
                                            }
                                        </Button>

                                          
                                       
                                    </form>


                                    <div className='d-flex justify-content-center'>
                                            <div className='marcaRegistrada'><span>®</span>2022 llaollao El Salvador</div>
                                    </div>
                                    
                                    <Row className='crearCuentaLinks'>
                                        <Col xs={12} className='d-flex justify-content-center'>
                                            <a href="/terminoscondiciones" target="_blank">Términos y condiciones</a> 
                                        </Col>
                                        <Col xs={12} className='d-flex justify-content-center'>
                                            <a href="/politicasprivacidad" target="_blank">Políticas de privacidad</a>
                                        </Col>
                                        <Col xs={12} className='d-flex justify-content-center'>
                                            <a href="/terminoscondicionesllaollao" target="_blank"> Términos y Condiciones llaollao club</a> 
                                        </Col>
                                    </Row>

                                </Col>
                                <Col xs={1}></Col>
                            </Row>
                        </Col>
                    </Row>
                    
                </div>
            </Row>
        </div>
      );
    }
}

export default CrearCuentaMovil;